<template>
  <v-app>
    <v-container v-if="isVerified" class="bg-white h-100 py-0">
      <v-row>
        <v-col v-if="isSubmitted" md="12" class="px-10 py-0">
          <div class="quotation-approved my-20">
            <div class="card">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                "
              >
                <i class="checkmark">✓</i>
              </div>
              <h1>Success</h1>
              <p>
                We received your approval against quotation #
                {{ quotation_detail.barcode }}<br />
                we'll be in touch shortly!<br />
                Thank You!
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-else md="12" class="px-10 py-0">
          <table width="100%">
            <thead>
              <tr>
                <td
                  colspan="10"
                  align="center"
                  class="custom-customer-border-bottom-2px"
                >
                  <v-img
                    lazy-src="https://fakeimg.pl/745x135"
                    max-height="135"
                    max-width="745"
                    :src="header_footer.quotation_header"
                  ></v-img>
                </td>
              </tr>
              <tr>
                <td height="50px" colspan="10" align="center" valign="center">
                  <h1 class="quotation">QUOTATION</h1>
                </td>
              </tr>
              <tr>
                <td width="70px" height="30px" valign="center">Our Ref:</td>
                <td height="30px" colspan="9" valign="center">
                  {{ quotation_detail.barcode }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="30px" valign="center">
                  {{ quotation_detail.date_formatted }}
                </td>
              </tr>
            </thead>
          </table>
          <table width="100%">
            <tbody>
              <tr>
                <td
                  colspan="10"
                  height="30px"
                  valign="center"
                  class="customer-font-bold"
                >
                  {{ quotation_detail.customer.company_name }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="20px" valign="center">
                  {{ quotation_detail.billing.unit_no }},
                  {{ quotation_detail.billing.street_1 }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="20px" valign="center">
                  {{ quotation_detail.billing.street_2 }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="20px" valign="center">
                  <template v-if="quotation_detail.billing.country">
                    {{ quotation_detail.billing.country.name }}
                  </template>
                  {{ quotation_detail.billing.zip_code }}
                </td>
              </tr>
              <tr>
                <td width="70px" height="30px" valign="center">Attn:</td>
                <td height="30px" colspan="4" valign="center">
                  {{ quotation_detail.attention }}
                </td>
                <td width="100px" height="30px" valign="center">
                  Contact no.:
                </td>
                <td height="30px" colspan="4" valign="center">
                  {{ quotation_detail.contact_person.primary_phone }}
                </td>
              </tr>
              <tr>
                <td width="70px" height="30px" valign="center">Email:</td>
                <td height="30px" colspan="9" valign="center">
                  {{ quotation_detail.contact_person.primary_email }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  height="30px"
                  valign="center"
                  class="customer-font-bold"
                >
                  {{ quotation_detail.job_title }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="30px" valign="center">
                  <div
                    class="pre-formatted"
                    v-html="quotation_detail.description"
                  ></div>
                </td>
              </tr>
              <tr class="line-items">
                <td
                  width="70px"
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-left"
                  align="center"
                  valign="center"
                >
                  No.
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-left customer-border-right"
                  colspan="6"
                  align="center"
                  valign="center"
                >
                  Description
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-right"
                  align="center"
                  valign="center"
                >
                  Qty
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-right"
                  colspan="2"
                  align="center"
                  valign="center"
                >
                  Price (SGD $)
                </td>
              </tr>
              <tr
                v-for="(line_item, index) in quotation_detail.line_items"
                :key="index"
                class="line-items"
              >
                <td
                  valign="top"
                  height="25px"
                  width="10px"
                  class="customer-border-bottom customer-border-left"
                  align="center"
                >
                  {{ index + 1 }}.
                </td>
                <td
                  valign="top"
                  height="25px"
                  class="customer-border-bottom customer-border-left"
                  align="left"
                  colspan="6"
                >
                  <template v-if="line_item.product">
                    {{ line_item.product.name }}
                  </template>
                  <template v-if="line_item.description">
                    <div
                      class="pre-formatted"
                      v-html="line_item.description"
                    ></div>
                  </template>
                </td>
                <td
                  valign="top"
                  height="25px"
                  class="customer-border-bottom customer-border-left"
                  align="center"
                >
                  {{ line_item.quantity }}
                  <template v-if="line_item.product && line_item.product.unit">
                    {{ line_item.product.unit.text }}
                  </template>
                </td>
                <td
                  valign="top"
                  height="25px"
                  class="customer-border-bottom customer-border-left customer-border-right"
                  align="center"
                  colspan="2"
                >
                  SGD
                  {{
                    getSubTotalAmount(line_item.sub_total, line_item.discount)
                  }}
                </td>
              </tr>
              <tr class="line-items">
                <td
                  class="light-gray-background customer-border-top customer-border-left customer-border-right"
                  colspan="8"
                  align="right"
                  valign="center"
                >
                  Total Price:&nbsp;&nbsp;
                </td>
                <td
                  class="light-gray-background customer-border-top"
                  align="center"
                  valign="center"
                >
                  SGD $
                </td>
                <td
                  class="light-gray-background customer-border-top customer-border-right"
                  align="left"
                  valign="center"
                >
                  {{ formatMoney(quotation_detail.sub_total) }}
                </td>
              </tr>
              <tr class="line-items">
                <td
                  class="light-gray-background customer-border-left customer-border-right"
                  colspan="8"
                  align="right"
                  valign="center"
                >
                  Less Discount:&nbsp;&nbsp;
                </td>
                <td
                  class="light-gray-background"
                  align="center"
                  valign="center"
                >
                  SGD $
                </td>
                <td
                  class="light-gray-background customer-border-right"
                  align="left"
                  valign="center"
                >
                  {{ formatMoney(quotation_detail.discount_value) }}
                </td>
              </tr>
              <tr class="line-items">
                <td
                  class="light-gray-background customer-font-bold customer-border-bottom customer-border-left customer-border-right"
                  colspan="8"
                  align="right"
                  valign="center"
                >
                  Total Price after discount:&nbsp;&nbsp;
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-bottom"
                  align="center"
                  valign="center"
                >
                  SGD $
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-bottom customer-border-right"
                  align="left"
                  valign="center"
                >
                  {{ formatMoney(quotation_detail.total) }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="10px"></td>
              </tr>
              <tr>
                <td
                  height="30px"
                  colspan="10"
                  align="left"
                  valign="center"
                  class="customer-font-bold"
                >
                  Notes:
                </td>
              </tr>
              <tr>
                <td colspan="10" align="left" valign="center">
                  <div
                    class="pre-formatted"
                    v-html="quotation_detail.client_remark"
                  ></div>
                </td>
              </tr>
              <tr>
                <td colspan="10" height="10px"></td>
              </tr>
              <tr>
                <td
                  height="30px"
                  colspan="10"
                  align="left"
                  valign="center"
                  class="customer-font-bold"
                >
                  Terms and Conditions:
                </td>
              </tr>
              <tr>
                <td colspan="10" align="left" valign="center">
                  <div
                    class="pre-formatted"
                    v-html="quotation_detail.term_conditions"
                  ></div>
                </td>
              </tr>
              <tr>
                <td height="30px" colspan="10" align="left" valign="center">
                  We trust above is in good order. Should you have any
                  clarifications, please do not hesitate to contact us.
                </td>
                <!-- Fixed Line For Every Quotation -->
              </tr>
              <tr>
                <td colspan="10" height="10px"></td>
              </tr>
            </tbody>
          </table>
          <table width="100%">
            <tbody>
              <tr>
                <td
                  height="30px"
                  width="50%"
                  colspan="5"
                  align="left"
                  valign="center"
                >
                  Prepared by:
                </td>
                <td
                  height="30px"
                  width="50%"
                  colspan="5"
                  align="left"
                  valign="center"
                >
                  Customer Acceptance:
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  width="50%"
                  align="left"
                  valign="center"
                  class="customer-font-bold"
                >
                  {{ company_detail.company_name }}
                </td>
                <td
                  colspan="5"
                  width="50%"
                  align="left"
                  valign="center"
                  class="customer-font-bold"
                >
                  <template v-if="quotation_detail.customer">
                    {{ quotation_detail.customer.company_name }}
                  </template>
                </td>
              </tr>
              <tr class="signature-pad" id="signature-pad">
                <td colspan="5" width="50%" align="left" valign="center">
                  <v-img
                    lazy-src="https://fakeimg.pl/542x140"
                    max-height="140"
                    max-width="542"
                    :src="sales_signature.sign_url"
                  ></v-img>
                </td>
                <td width="50%" colspan="5" align="left" valign="center">
                  <div
                    ref="customer_signature_div"
                    style="width: 100px"
                    class="custom-border-grey-dashed"
                  >
                    <canvas ref="customer_signature"></canvas>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  width="50%"
                  height="30px"
                  colspan="5"
                  align="left"
                  valign="center"
                  class="customer-font-bold"
                >
                  {{ quotation_detail.sales }}
                </td>
                <td colspan="5" width="50%" align="left" valign="center">
                  Authorized Signature/Company Stamp
                  <p class="float-right m-0">
                    <v-btn
                      content="Click here to clear signature"
                      v-tippy="{ arrow: true, placement: 'top' }"
                      icon
                      small
                      class="float-right"
                      v-on:click="customer_signature.clear()"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="5" width="50%" align="left" valign="center">
                  &nbsp;
                </td>
                <td colspan="5" width="50%" align="left" valign="center">
                  Date: {{ today_date }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="50px" align="right" valign="center">
                  <v-btn
                    :disabled="validSignature || formLoading || pageLoading"
                    :loading="formLoading"
                    class="custom-grey-border custom-bold-button white--text"
                    color="cyan"
                    v-on:click="submitSignature"
                  >
                    Submit
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%">
            <tfoot>
              <tr>
                <td
                  height="30px"
                  colspan="10"
                  align="center"
                  valign="center"
                  class="custom-border-top"
                  style="font-size: 10px"
                >
                  {{ company_detail.street_1 }}, {{ company_detail.street_2 }},
                  {{ company_detail.landmark }}
                  {{ company_detail.zip_code }} Tel:
                  {{ company_detail.phone_number }} •
                  {{ company_detail.website_url }} •
                  {{ company_detail.email_address }}
                </td>
              </tr>
            </tfoot>
          </table>
        </v-col>
      </v-row>
    </v-container>
    <Dialog :commonDialog.sync="dialog">
      <template v-slot:title> Verification </template>
      <template v-slot:body>
        <v-form
          ref="verificationForm"
          v-model="formValid"
          lazy-validation
          v-on:submit.stop.prevent="verifyCustomer"
        >
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model="verificationCode"
                dense
                filled
                label="Security Code"
                solo
                flat
                :disabled="formLoading || pageLoading"
                :loading="formLoading"
                color="cyan"
                :rules="[
                  validateRules.required(verificationCode, 'Security Code'),
                  validateRules.minLength(verificationCode, 'Security Code', 4),
                  validateRules.maxLength(
                    verificationCode,
                    'Security Code',
                    10
                  ),
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="verifyCustomer"
        >
          Submit
        </v-btn>
      </template>
    </Dialog>
    <template v-if="validationErrorBag.length">
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        color="error"
        right
        v-for="(error, index) in validationErrorBag"
        :key="index"
      >
        {{ error }}
      </v-snackbar>
    </template>
  </v-app>
</template>
<style type="text/css" scoped>
.quotation {
  font-weight: 600;
  text-decoration: underline;
}
.customer-font-bold {
  font-weight: 600;
}
.customer-border-top {
  border-top: solid 2px #000;
}
.customer-border-bottom {
  border-bottom: solid 2px #000;
}
.customer-border-right {
  border-right: solid 2px #000;
}
.customer-border-left {
  border-left: solid 2px #000;
}
.line-items td {
  padding: 4px;
}
table tbody td:first-child {
  width: 70px !important;
}
table tbody td:nth-child(3) {
  width: 100px !important;
}
table > tbody > tr.signature-pad > td {
  width: 50% !important;
}
.quotation-approved {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
}

.quotation-approved h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.quotation-approved p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.quotation-approved i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.quotation-approved .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
</style>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { VERIFY_QUOTATION_CUSTOMER } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin.js";
import SignaturePad from "signature_pad";

export default {
  name: "quotation-approve",
  mixins: [ValidationMixin, CommonMixin],
  data: () => {
    return {
      dialog: true,
      formValid: true,
      formLoading: false,
      isVerified: false,
      isSubmitted: false,
      pageLoading: false,
      tokenNotFound: false,
      verificationCode: null,
      securityToken: null,
      today_date: null,
      customer_signature: null,
      validationErrorBag: new Array(),
      quotation_detail: new Object({
        id: null,
        barcode: null,
        customer: {
          display_name: null,
          company_name: null,
        },
        billing: {
          unit_no: null,
          street_1: null,
          street_2: null,
          zip_code: null,
          country: {
            name: null,
          },
          property_address: null,
        },
        job_title: null,
        sales: null,
        attention: null,
        client_remark: null,
        term_conditions: null,
        additional_remarks: null,
        total: null,
        sub_total: null,
        discount_value: null,
        date: null,
        date_formatted: null,
        contact_person: {
          primary_phone: null,
          primary_email: null,
          full_name: null,
        },
        line_items: [
          {
            id: null,
            description: null,
            quantity: null,
            product: {
              name: null,
              unit: {
                id: null,
                text: null,
              },
            },
            sub_total: null,
            discount: null,
          },
        ],
      }),
      company_detail: new Object(),
      header_footer: new Object(),
      sales_signature: new Object(),
    };
  },
  components: {
    Dialog,
  },
  methods: {
    getSubTotalAmount(sub_total, discount) {
      return this.formatMoney(this.lodash.sum([sub_total, discount]));
    },
    submitSignature() {
      const _this = this;

      if (_this.customer_signature.isEmpty()) {
        return false;
      }

      _this.formLoading = true;
      _this.isSubmitted = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_QUOTATION_CUSTOMER, {
          url: "quotation/customer/submit",
          data: {
            customer: _this.customer_signature.toDataURL(),
            code: _this.verificationCode,
          },
          token: _this.securityToken,
        })
        .then(() => {
          _this.isSubmitted = true;
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    verifyCustomer() {
      const _this = this;

      if (!_this.$refs.verificationForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.isVerified = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_QUOTATION_CUSTOMER, {
          url: "quotation/verify",
          data: { code: _this.verificationCode },
          token: _this.securityToken,
        })
        .then(({ data }) => {
          _this.quotation_detail = data.quotation_detail;
          _this.company_detail = data.company_detail;
          _this.header_footer = data.header_footer;
          _this.sales_signature = data.sales_signature;
          _this.today_date = data.today_date;
          _this.dialog = false;
          _this.isVerified = true;
          _this.initSignature();
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    initSignature() {
      const _this = this;
      _this.$nextTick(() => {
        let ccanvas = _this.$refs["customer_signature"];
        let ccanvasDiv = _this.$refs["customer_signature_div"];
        let cparentDiv = document.getElementById("signature-pad");
        let cparentWidth = cparentDiv.offsetWidth / 2;
        ccanvas.setAttribute("width", cparentWidth);
        ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
        _this.customer_signature = new SignaturePad(ccanvas);
      });
    },
  },
  created() {
    const _this = this;

    if (_this.$route.query && _this.$route.query.token) {
      _this.securityToken = _this.$route.query.token;
    } else {
      _this.dialog = false;
      _this.tokenNotFound = true;
    }
  },
  computed: {
    validSignature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
  },
};
</script>
